import { createStore } from "vuex";
import axios from "axios";
import jwtDecode from "jwt-decode";

import createPersistedState from "vuex-persistedstate";

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("accessToken");
    if (!!token && token !== "null") {
      config.headers.Authorization = `JWT ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error.request);
  }
);

const user = {
  namespaced: true,
  state: () => ({
    token: localStorage.getItem("accessToken") || "",
    user_name: "",
  }),
  mutations: {
    UPDATE_TOKEN(state, response) {
      state.token = response.data.token;
      window.localStorage.accessToken = state.token;
    },
    REMOVE_TOKENS(state) {
      window.localStorage.removeItem("accessToken");
      state.token = "";
    },
    UPDATE_USERNAME(state, name) {
      state.user_name = name;
    },
  },
  actions: {
    LOGIN({ commit }, payload) {
      return axios.post(`/api/ops/token/`, payload).then((res) => {
        commit("UPDATE_TOKEN", res);
        return res;
      });
    },
    GET_USERINFO({ commit }) {
      return axios.get("/rest-auth/user/").then((res) => {
        if (res.status == 200) {
          commit("UPDATE_USERNAME", res.data.name);
        }
        return res;
      });
    },
    LOGOUT({ commit }) {
      commit("REMOVE_TOKENS");
      return true;
    },
  },
  getters: {
    getUserId(state) {
      if (!state.token) {
        return false;
      }
      return jwtDecode(state.token).user_id;
    },
  },
};

const rental = {
  namespaced: true,
  state: () => ({
    install_date: "",
    event_name: "",
    order_num: "",
    install_start_date: "",
    status: [0, 1, 2, 3, 4, 5, 6],
    order_by: "-created_at",
    page_number: 1,
  }),
  mutations: {
    SET_FILTER(state, filter) {
      Object.keys(filter).forEach((key) => {
        if (state.hasOwnProperty(key)) {
          state[key] = filter[key];
        }
      });
    },
    SET_INSTALL_DATE(state, install_date) {
      state.install_date = install_date;
    },
    SET_SUPPLEMENTS(state, res) {
      state.supplements = res.data;
    },
  },
  actions: {
    GET_RENTAL_FIELDS() {
      return axios.get(`/api/ops/rental-fields/`);
    },
    GET_RENTAL_LIST({ getters }) {
      const filter_query = new URLSearchParams(getters.filter).toString();
      return axios.get(`/api/ops/rental/?${filter_query}`);
    },
    GET_RENTAL({}, order_id) {
      return axios.get(`/api/ops/rental/order/${order_id}/`);
    },
    UPDATE_RENTAL({}, { id, payload }) {
      return axios.put(`/api/ops/rental/${id}/`, payload);
    },
    GET_SUPPLEMENT({}, order_id) {
      return axios.get(`/api/ops/supplement/?order_id=${order_id}`);
    },
  },
  getters: {
    filter(state) {
      return {
        install_date: state.install_date,
        event_name: state.event_name,
        order_num: state.order_num,
        install_start_date: state.install_start_date,
        status: state.status,
        page_number: state.page_number,
        order_by: state.order_by,
        page_size: 40,
      };
    },
  },
};

const order = {
  namespaced: true,
  state: () => ({
    order: "",
    onsite_info: "",
    cart_list: "",
    cart_info: "",
    delivery_fee: 0,
    region: "",
  }),
  mutations: {
    SET_ORDER(state, order) {
      state.order = order;
    },
    SET_ONSITE_INFO(state, onsite_info) {
      state.onsite_info = onsite_info;
    },
    SET_CART_LIST(state, cart_list) {
      state.cart_list = cart_list;
    },
    SET_CART_INFO(state, cart_info) {
      state.cart_info = cart_info;
    },
    SET_DELIVERY_FEE(state, delivery_fee) {
      state.delivery_fee = delivery_fee;
    },
    SET_REGION(state, region) {
      state.region = region;
    },
    RESET_ORDER(state) {
      state.order = "";
      state.onsite_info = "";
      state.cart_list = "";
      state.cart_info = "";
      state.delivery_fee = 0;
      state.region = "";
    },
  },
  actions: {
    async GET_ORDER({ commit }, order_num) {
      try {
        const response = await axios.get(`/api/order/${order_num}/`);
        commit("SET_ORDER", response.data);
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    },
    UPDATE_ORDER({ state }, extra_info) {
      const payload = {
        order_num: state.order.order_num,
        onsite_info: {
          ...state.onsite_info,
          install_date: state.onsite_info.install_date.split(".")[0],
          uninstall_date: state.onsite_info.uninstall_date.split(".")[0],
        },
        cart_info: state.cart_list,
        delivery_fee: state.order.delivery_fee,
        region: state.region,
        ...extra_info,
      };
      return axios.post(`/api/edit/`, payload);
    },
  },
};

const common = {
  namespaced: true,
  state: () => ({
    search_date: "",
  }),
  mutations: {
    SET_SEARCH_DATE(state, date) {
      state.search_date = date;
    },
  },
  actions: {
    GET_PRODUCTS({}, payload) {
      const params = new URLSearchParams(payload).toString();
      return axios.get(`/api/shop/product/`).then((res) => {
        return res;
      });
    },
    GET_PRODUCT_AVAILABLES({}, params) {
      return axios.get(
        `/api/shop/product/availables/${params.start_date}/${params.end_date}`
      );
    },
  },
};

export const store = createStore({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  modules: {
    user: user,
    rental: rental,
    order: order,
    common: common,
  },
});
